import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Space_Grotesk\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-space-grotesk\"}],\"variableName\":\"space_grotesk\"}");
;
import(/* webpackMode: "eager", webpackExports: ["NextAuthProviders"] */ "/vercel/path0/src/components/providers/nextauth-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextUIProviders"] */ "/vercel/path0/src/components/providers/nextui-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProviders"] */ "/vercel/path0/src/components/providers/theme-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/src/components/ui/Toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Widget"] */ "/vercel/path0/src/components/ui/Widget.tsx");
